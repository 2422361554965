<template>
  <div  class="msg">
      {{msg}}

    </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
 .msg{
        position:fixed;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        background-color: rgba(0,0,0,0.5);
        color: white;
        padding:0.5rem 1rem;
        border-radius: 10px;
        animation: TipLayer 2s;
    }
</style>

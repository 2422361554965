<template>
  <div class="about">
        <HelloWorld v-if="url!=''" :msg="url"/>
  </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
    data () {
    return {
      url:'',                                           
   
    }
  },
   mounted(){
 console.log(this.$route.query.name);
     if(this.$route.query.name==1){
       this.url = 'https://content.95516.com/UCIWeb/outputWeb/coupon/coupon.html#/couponDetail?couponId=3102021091402484'
     }else if(this.$route.query.name==2){
       this.url = 'https://content.95516.com/UCIWeb/outputWeb/coupon/coupon.html#/couponDetail?couponId=3102021091402482'

     }else if(this.$route.query.name==3){
       this.url = 'https://content.95516.com/UCIWeb/outputWeb/coupon/coupon.html#/couponDetail?couponId=3102021091402483'

     }
   }
 
}
</script>